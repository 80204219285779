import _commons from "@sinonjs/commons";
import _util from "util";
var exports = {};
var globalObject = _commons.global; // eslint-disable-next-line complexity

function withGlobal(_global) {
  var userAgent = _global.navigator && _global.navigator.userAgent;
  var isRunningInIE = userAgent && userAgent.indexOf("MSIE ") > -1;
  var maxTimeout = Math.pow(2, 31) - 1; //see https://heycam.github.io/webidl/#abstract-opdef-converttoint

  var NOOP = function () {
    return undefined;
  };

  var NOOP_ARRAY = function () {
    return [];
  };

  var timeoutResult = _global.setTimeout(NOOP, 0);

  var addTimerReturnsObject = typeof timeoutResult === "object";
  var hrtimePresent = _global.process && typeof _global.process.hrtime === "function";
  var hrtimeBigintPresent = hrtimePresent && typeof _global.process.hrtime.bigint === "function";
  var nextTickPresent = _global.process && typeof _global.process.nextTick === "function";
  var utilPromisify = _global.process && _util.promisify;
  var performancePresent = _global.performance && typeof _global.performance.now === "function";
  var hasPerformancePrototype = _global.Performance && (typeof _global.Performance).match(/^(function|object)$/);

  var queueMicrotaskPresent = _global.hasOwnProperty("queueMicrotask");

  var requestAnimationFramePresent = _global.requestAnimationFrame && typeof _global.requestAnimationFrame === "function";
  var cancelAnimationFramePresent = _global.cancelAnimationFrame && typeof _global.cancelAnimationFrame === "function";
  var requestIdleCallbackPresent = _global.requestIdleCallback && typeof _global.requestIdleCallback === "function";
  var cancelIdleCallbackPresent = _global.cancelIdleCallback && typeof _global.cancelIdleCallback === "function";
  var setImmediatePresent = _global.setImmediate && typeof _global.setImmediate === "function"; // Make properties writable in IE, as per
  // http://www.adequatelygood.com/Replacing-setTimeout-Globally.html

  /* eslint-disable no-self-assign */

  if (isRunningInIE) {
    _global.setTimeout = _global.setTimeout;
    _global.clearTimeout = _global.clearTimeout;
    _global.setInterval = _global.setInterval;
    _global.clearInterval = _global.clearInterval;
    _global.Date = _global.Date;
  } // setImmediate is not a standard function
  // avoid adding the prop to the window object if not present


  if (setImmediatePresent) {
    _global.setImmediate = _global.setImmediate;
    _global.clearImmediate = _global.clearImmediate;
  }
  /* eslint-enable no-self-assign */


  _global.clearTimeout(timeoutResult);

  var NativeDate = _global.Date;
  var uniqueTimerId = 1;

  function isNumberFinite(num) {
    if (Number.isFinite) {
      return Number.isFinite(num);
    }

    if (typeof num !== "number") {
      return false;
    }

    return isFinite(num);
  }
  /**
   * Parse strings like "01:10:00" (meaning 1 hour, 10 minutes, 0 seconds) into
   * number of milliseconds. This is used to support human-readable strings passed
   * to clock.tick()
   */


  function parseTime(str) {
    if (!str) {
      return 0;
    }

    var strings = str.split(":");
    var l = strings.length;
    var i = l;
    var ms = 0;
    var parsed;

    if (l > 3 || !/^(\d\d:){0,2}\d\d?$/.test(str)) {
      throw new Error("tick only understands numbers, 'm:s' and 'h:m:s'. Each part must be two digits");
    }

    while (i--) {
      parsed = parseInt(strings[i], 10);

      if (parsed >= 60) {
        throw new Error("Invalid time " + str);
      }

      ms += parsed * Math.pow(60, l - i - 1);
    }

    return ms * 1000;
  }
  /**
   * Get the decimal part of the millisecond value as nanoseconds
   *
   * @param {Number} msFloat the number of milliseconds
   * @returns {Number} an integer number of nanoseconds in the range [0,1e6)
   *
   * Example: nanoRemainer(123.456789) -> 456789
   */


  function nanoRemainder(msFloat) {
    var modulo = 1000000;
    var remainder = msFloat * 1000000 % modulo;
    var positiveRemainder = remainder < 0 ? remainder + modulo : remainder;
    return Math.floor(positiveRemainder);
  }
  /**
   * Used to grok the `now` parameter to createClock.
   * @param epoch {Date|number} the system time
   */


  function getEpoch(epoch) {
    if (!epoch) {
      return 0;
    }

    if (typeof epoch.getTime === "function") {
      return epoch.getTime();
    }

    if (typeof epoch === "number") {
      return epoch;
    }

    throw new TypeError("now should be milliseconds since UNIX epoch");
  }

  function inRange(from, to, timer) {
    return timer && timer.callAt >= from && timer.callAt <= to;
  }

  function mirrorDateProperties(target, source) {
    var prop;

    for (prop in source) {
      if (source.hasOwnProperty(prop)) {
        target[prop] = source[prop];
      }
    } // set special now implementation


    if (source.now) {
      target.now = function now() {
        return target.clock.now;
      };
    } else {
      delete target.now;
    } // set special toSource implementation


    if (source.toSource) {
      target.toSource = function toSource() {
        return source.toSource();
      };
    } else {
      delete target.toSource;
    } // set special toString implementation


    target.toString = function toString() {
      return source.toString();
    };

    target.prototype = source.prototype;
    target.parse = source.parse;
    target.UTC = source.UTC;
    target.prototype.toUTCString = source.prototype.toUTCString;
    return target;
  }

  function createDate() {
    function ClockDate(year, month, date, hour, minute, second, ms) {
      // the Date constructor called as a function, ref Ecma-262 Edition 5.1, section 15.9.2.
      // This remains so in the 10th edition of 2019 as well.
      if (!(this instanceof ClockDate)) {
        return new NativeDate(ClockDate.clock.now).toString();
      } // if Date is called as a constructor with 'new' keyword
      // Defensive and verbose to avoid potential harm in passing
      // explicit undefined when user does not pass argument


      switch (arguments.length) {
        case 0:
          return new NativeDate(ClockDate.clock.now);

        case 1:
          return new NativeDate(year);

        case 2:
          return new NativeDate(year, month);

        case 3:
          return new NativeDate(year, month, date);

        case 4:
          return new NativeDate(year, month, date, hour);

        case 5:
          return new NativeDate(year, month, date, hour, minute);

        case 6:
          return new NativeDate(year, month, date, hour, minute, second);

        default:
          return new NativeDate(year, month, date, hour, minute, second, ms);
      }
    }

    return mirrorDateProperties(ClockDate, NativeDate);
  }

  function enqueueJob(clock, job) {
    // enqueues a microtick-deferred task - ecma262/#sec-enqueuejob
    if (!clock.jobs) {
      clock.jobs = [];
    }

    clock.jobs.push(job);
  }

  function runJobs(clock) {
    // runs all microtick-deferred tasks - ecma262/#sec-runjobs
    if (!clock.jobs) {
      return;
    }

    for (var i = 0; i < clock.jobs.length; i++) {
      var job = clock.jobs[i];
      job.func.apply(null, job.args);

      if (clock.loopLimit && i > clock.loopLimit) {
        throw new Error("Aborting after running " + clock.loopLimit + " timers, assuming an infinite loop!");
      }
    }

    clock.jobs = [];
  }

  function addTimer(clock, timer) {
    if (timer.func === undefined) {
      throw new Error("Callback must be provided to timer calls");
    }

    timer.type = timer.immediate ? "Immediate" : "Timeout";

    if (timer.hasOwnProperty("delay")) {
      if (!isNumberFinite(timer.delay)) {
        timer.delay = 0;
      }

      timer.delay = timer.delay > maxTimeout ? 1 : timer.delay;
      timer.delay = Math.max(0, timer.delay);
    }

    if (timer.hasOwnProperty("interval")) {
      timer.type = "Interval";
      timer.interval = timer.interval > maxTimeout ? 1 : timer.interval;
    }

    if (timer.hasOwnProperty("animation")) {
      timer.type = "AnimationFrame";
      timer.animation = true;
    }

    if (!clock.timers) {
      clock.timers = {};
    }

    timer.id = uniqueTimerId++;
    timer.createdAt = clock.now;
    timer.callAt = clock.now + (parseInt(timer.delay) || (clock.duringTick ? 1 : 0));
    clock.timers[timer.id] = timer;

    if (addTimerReturnsObject) {
      var res = {
        id: timer.id,
        ref: function () {
          return res;
        },
        unref: function () {
          return res;
        },
        refresh: function () {
          return res;
        }
      };
      return res;
    }

    return timer.id;
  }
  /* eslint consistent-return: "off" */


  function compareTimers(a, b) {
    // Sort first by absolute timing
    if (a.callAt < b.callAt) {
      return -1;
    }

    if (a.callAt > b.callAt) {
      return 1;
    } // Sort next by immediate, immediate timers take precedence


    if (a.immediate && !b.immediate) {
      return -1;
    }

    if (!a.immediate && b.immediate) {
      return 1;
    } // Sort next by creation time, earlier-created timers take precedence


    if (a.createdAt < b.createdAt) {
      return -1;
    }

    if (a.createdAt > b.createdAt) {
      return 1;
    } // Sort next by id, lower-id timers take precedence


    if (a.id < b.id) {
      return -1;
    }

    if (a.id > b.id) {
      return 1;
    } // As timer ids are unique, no fallback `0` is necessary

  }

  function firstTimerInRange(clock, from, to) {
    var timers = clock.timers;
    var timer = null;
    var id, isInRange;

    for (id in timers) {
      if (timers.hasOwnProperty(id)) {
        isInRange = inRange(from, to, timers[id]);

        if (isInRange && (!timer || compareTimers(timer, timers[id]) === 1)) {
          timer = timers[id];
        }
      }
    }

    return timer;
  }

  function firstTimer(clock) {
    var timers = clock.timers;
    var timer = null;
    var id;

    for (id in timers) {
      if (timers.hasOwnProperty(id)) {
        if (!timer || compareTimers(timer, timers[id]) === 1) {
          timer = timers[id];
        }
      }
    }

    return timer;
  }

  function lastTimer(clock) {
    var timers = clock.timers;
    var timer = null;
    var id;

    for (id in timers) {
      if (timers.hasOwnProperty(id)) {
        if (!timer || compareTimers(timer, timers[id]) === -1) {
          timer = timers[id];
        }
      }
    }

    return timer;
  }

  function callTimer(clock, timer) {
    if (typeof timer.interval === "number") {
      clock.timers[timer.id].callAt += timer.interval;
    } else {
      delete clock.timers[timer.id];
    }

    if (typeof timer.func === "function") {
      timer.func.apply(null, timer.args);
    } else {
      /* eslint no-eval: "off" */
      eval(timer.func);
    }
  }

  function clearTimer(clock, timerId, ttype) {
    if (!timerId) {
      // null appears to be allowed in most browsers, and appears to be
      // relied upon by some libraries, like Bootstrap carousel
      return;
    }

    if (!clock.timers) {
      clock.timers = {};
    } // in Node, timerId is an object with .ref()/.unref(), and
    // its .id field is the actual timer id.


    var id = typeof timerId === "object" ? timerId.id : timerId;

    if (clock.timers.hasOwnProperty(id)) {
      // check that the ID matches a timer of the correct type
      var timer = clock.timers[id];

      if (timer.type === ttype) {
        delete clock.timers[id];
      } else {
        var clear = ttype === "AnimationFrame" ? "cancelAnimationFrame" : "clear" + ttype;
        var schedule = timer.type === "AnimationFrame" ? "requestAnimationFrame" : "set" + timer.type;
        throw new Error("Cannot clear timer: timer created with " + schedule + "() but cleared with " + clear + "()");
      }
    }
  }

  function uninstall(clock, target, config) {
    var method, i, l;
    var installedHrTime = "_hrtime";
    var installedNextTick = "_nextTick";

    for (i = 0, l = clock.methods.length; i < l; i++) {
      method = clock.methods[i];

      if (method === "hrtime" && target.process) {
        target.process.hrtime = clock[installedHrTime];
      } else if (method === "nextTick" && target.process) {
        target.process.nextTick = clock[installedNextTick];
      } else if (method === "performance") {
        var originalPerfDescriptor = Object.getOwnPropertyDescriptor(clock, "_" + method);

        if (originalPerfDescriptor && originalPerfDescriptor.get && !originalPerfDescriptor.set) {
          Object.defineProperty(target, method, originalPerfDescriptor);
        } else if (originalPerfDescriptor.configurable) {
          target[method] = clock["_" + method];
        }
      } else {
        if (target[method] && target[method].hadOwnProperty) {
          target[method] = clock["_" + method];

          if (method === "clearInterval" && config.shouldAdvanceTime === true) {
            target[method](clock.attachedInterval);
          }
        } else {
          try {
            delete target[method];
          } catch (ignore) {
            /* eslint no-empty: "off" */
          }
        }
      }
    } // Prevent multiple executions which will completely remove these props


    clock.methods = []; // return pending timers, to enable checking what timers remained on uninstall

    if (!clock.timers) {
      return [];
    }

    return Object.keys(clock.timers).map(function mapper(key) {
      return clock.timers[key];
    });
  }

  function hijackMethod(target, method, clock) {
    var prop;
    clock[method].hadOwnProperty = Object.prototype.hasOwnProperty.call(target, method);
    clock["_" + method] = target[method];

    if (method === "Date") {
      var date = mirrorDateProperties(clock[method], target[method]);
      target[method] = date;
    } else if (method === "performance") {
      var originalPerfDescriptor = Object.getOwnPropertyDescriptor(target, method); // JSDOM has a read only performance field so we have to save/copy it differently

      if (originalPerfDescriptor && originalPerfDescriptor.get && !originalPerfDescriptor.set) {
        Object.defineProperty(clock, "_" + method, originalPerfDescriptor);
        var perfDescriptor = Object.getOwnPropertyDescriptor(clock, method);
        Object.defineProperty(target, method, perfDescriptor);
      } else {
        target[method] = clock[method];
      }
    } else {
      target[method] = function () {
        return clock[method].apply(clock, arguments);
      };

      for (prop in clock[method]) {
        if (clock[method].hasOwnProperty(prop)) {
          target[method][prop] = clock[method][prop];
        }
      }
    }

    target[method].clock = clock;
  }

  function doIntervalTick(clock, advanceTimeDelta) {
    clock.tick(advanceTimeDelta);
  }

  var timers = {
    setTimeout: _global.setTimeout,
    clearTimeout: _global.clearTimeout,
    setInterval: _global.setInterval,
    clearInterval: _global.clearInterval,
    Date: _global.Date
  };

  if (setImmediatePresent) {
    timers.setImmediate = _global.setImmediate;
    timers.clearImmediate = _global.clearImmediate;
  }

  if (hrtimePresent) {
    timers.hrtime = _global.process.hrtime;
  }

  if (nextTickPresent) {
    timers.nextTick = _global.process.nextTick;
  }

  if (performancePresent) {
    timers.performance = _global.performance;
  }

  if (requestAnimationFramePresent) {
    timers.requestAnimationFrame = _global.requestAnimationFrame;
  }

  if (queueMicrotaskPresent) {
    timers.queueMicrotask = true;
  }

  if (cancelAnimationFramePresent) {
    timers.cancelAnimationFrame = _global.cancelAnimationFrame;
  }

  if (requestIdleCallbackPresent) {
    timers.requestIdleCallback = _global.requestIdleCallback;
  }

  if (cancelIdleCallbackPresent) {
    timers.cancelIdleCallback = _global.cancelIdleCallback;
  }

  var keys = Object.keys || function (obj) {
    var ks = [];
    var key;

    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        ks.push(key);
      }
    }

    return ks;
  };

  var originalSetTimeout = _global.setImmediate || _global.setTimeout;
  /**
   * @param start {Date|number} the system time - non-integer values are floored
   * @param loopLimit {number}  maximum number of timers that will be run when calling runAll()
   */

  function createClock(start, loopLimit) {
    // eslint-disable-next-line no-param-reassign
    start = Math.floor(getEpoch(start)); // eslint-disable-next-line no-param-reassign

    loopLimit = loopLimit || 1000;
    var nanos = 0;
    var adjustedSystemTime = [0, 0]; // [millis, nanoremainder]

    if (NativeDate === undefined) {
      throw new Error("The global scope doesn't have a `Date` object" + " (see https://github.com/sinonjs/sinon/issues/1852#issuecomment-419622780)");
    }

    var clock = {
      now: start,
      timeouts: {},
      Date: createDate(),
      loopLimit: loopLimit
    };
    clock.Date.clock = clock;

    function getTimeToNextFrame() {
      return 16 - (clock.now - start) % 16;
    }

    function hrtime(prev) {
      var millisSinceStart = clock.now - adjustedSystemTime[0] - start;
      var secsSinceStart = Math.floor(millisSinceStart / 1000);
      var remainderInNanos = (millisSinceStart - secsSinceStart * 1000) * 1000000 + nanos - adjustedSystemTime[1];

      if (Array.isArray(prev)) {
        if (prev[1] > 1000000000) {
          throw new TypeError("Number of nanoseconds can't exceed a billion");
        }

        var oldSecs = prev[0];
        var nanoDiff = remainderInNanos - prev[1];
        var secDiff = secsSinceStart - oldSecs;

        if (nanoDiff < 0) {
          nanoDiff += 1000000000;
          secDiff -= 1;
        }

        return [secDiff, nanoDiff];
      }

      return [secsSinceStart, remainderInNanos];
    }

    if (hrtimeBigintPresent) {
      hrtime.bigint = function () {
        var parts = hrtime();
        return BigInt(parts[0]) * BigInt(1000000000) + BigInt(parts[1]); // eslint-disable-line
      };
    }

    clock.requestIdleCallback = function requestIdleCallback(func, timeout) {
      var timeToNextIdlePeriod = 0;

      if (clock.countTimers() > 0) {
        timeToNextIdlePeriod = 50; // const for now
      }

      var result = addTimer(clock, {
        func: func,
        args: Array.prototype.slice.call(arguments, 2),
        delay: typeof timeout === "undefined" ? timeToNextIdlePeriod : Math.min(timeout, timeToNextIdlePeriod)
      });
      return result.id || result;
    };

    clock.cancelIdleCallback = function cancelIdleCallback(timerId) {
      return clearTimer(clock, timerId, "Timeout");
    };

    clock.setTimeout = function setTimeout(func, timeout) {
      return addTimer(clock, {
        func: func,
        args: Array.prototype.slice.call(arguments, 2),
        delay: timeout
      });
    };

    if (typeof _global.Promise !== "undefined" && utilPromisify) {
      clock.setTimeout[utilPromisify.custom] = function promisifiedSetTimeout(timeout, arg) {
        return new _global.Promise(function setTimeoutExecutor(resolve) {
          addTimer(clock, {
            func: resolve,
            args: [arg],
            delay: timeout
          });
        });
      };
    }

    clock.clearTimeout = function clearTimeout(timerId) {
      return clearTimer(clock, timerId, "Timeout");
    };

    clock.nextTick = function nextTick(func) {
      return enqueueJob(clock, {
        func: func,
        args: Array.prototype.slice.call(arguments, 1)
      });
    };

    clock.queueMicrotask = function queueMicrotask(func) {
      return clock.nextTick(func); // explicitly drop additional arguments
    };

    clock.setInterval = function setInterval(func, timeout) {
      // eslint-disable-next-line no-param-reassign
      timeout = parseInt(timeout, 10);
      return addTimer(clock, {
        func: func,
        args: Array.prototype.slice.call(arguments, 2),
        delay: timeout,
        interval: timeout
      });
    };

    clock.clearInterval = function clearInterval(timerId) {
      return clearTimer(clock, timerId, "Interval");
    };

    if (setImmediatePresent) {
      clock.setImmediate = function setImmediate(func) {
        return addTimer(clock, {
          func: func,
          args: Array.prototype.slice.call(arguments, 1),
          immediate: true
        });
      };

      if (typeof _global.Promise !== "undefined" && utilPromisify) {
        clock.setImmediate[utilPromisify.custom] = function promisifiedSetImmediate(arg) {
          return new _global.Promise(function setImmediateExecutor(resolve) {
            addTimer(clock, {
              func: resolve,
              args: [arg],
              immediate: true
            });
          });
        };
      }

      clock.clearImmediate = function clearImmediate(timerId) {
        return clearTimer(clock, timerId, "Immediate");
      };
    }

    clock.countTimers = function countTimers() {
      return Object.keys(clock.timers || {}).length + (clock.jobs || []).length;
    };

    clock.requestAnimationFrame = function requestAnimationFrame(func) {
      var result = addTimer(clock, {
        func: func,
        delay: getTimeToNextFrame(),
        args: [clock.now + getTimeToNextFrame()],
        animation: true
      });
      return result.id || result;
    };

    clock.cancelAnimationFrame = function cancelAnimationFrame(timerId) {
      return clearTimer(clock, timerId, "AnimationFrame");
    };

    clock.runMicrotasks = function runMicrotasks() {
      runJobs(clock);
    };

    function doTick(tickValue, isAsync, resolve, reject) {
      var msFloat = typeof tickValue === "number" ? tickValue : parseTime(tickValue);
      var ms = Math.floor(msFloat);
      var remainder = nanoRemainder(msFloat);
      var nanosTotal = nanos + remainder;
      var tickTo = clock.now + ms;

      if (msFloat < 0) {
        throw new TypeError("Negative ticks are not supported");
      } // adjust for positive overflow


      if (nanosTotal >= 1000000) {
        tickTo += 1;
        nanosTotal -= 1000000;
      }

      nanos = nanosTotal;
      var tickFrom = clock.now;
      var previous = clock.now;
      var timer, firstException, oldNow, nextPromiseTick, compensationCheck, postTimerCall;
      clock.duringTick = true; // perform microtasks

      oldNow = clock.now;
      runJobs(clock);

      if (oldNow !== clock.now) {
        // compensate for any setSystemTime() call during microtask callback
        tickFrom += clock.now - oldNow;
        tickTo += clock.now - oldNow;
      }

      function doTickInner() {
        // perform each timer in the requested range
        timer = firstTimerInRange(clock, tickFrom, tickTo); // eslint-disable-next-line no-unmodified-loop-condition

        while (timer && tickFrom <= tickTo) {
          if (clock.timers[timer.id]) {
            tickFrom = timer.callAt;
            clock.now = timer.callAt;
            oldNow = clock.now;

            try {
              runJobs(clock);
              callTimer(clock, timer);
            } catch (e) {
              firstException = firstException || e;
            }

            if (isAsync) {
              // finish up after native setImmediate callback to allow
              // all native es6 promises to process their callbacks after
              // each timer fires.
              originalSetTimeout(nextPromiseTick);
              return;
            }

            compensationCheck();
          }

          postTimerCall();
        } // perform process.nextTick()s again


        oldNow = clock.now;
        runJobs(clock);

        if (oldNow !== clock.now) {
          // compensate for any setSystemTime() call during process.nextTick() callback
          tickFrom += clock.now - oldNow;
          tickTo += clock.now - oldNow;
        }

        clock.duringTick = false; // corner case: during runJobs new timers were scheduled which could be in the range [clock.now, tickTo]

        timer = firstTimerInRange(clock, tickFrom, tickTo);

        if (timer) {
          try {
            clock.tick(tickTo - clock.now); // do it all again - for the remainder of the requested range
          } catch (e) {
            firstException = firstException || e;
          }
        } else {
          // no timers remaining in the requested range: move the clock all the way to the end
          clock.now = tickTo; // update nanos

          nanos = nanosTotal;
        }

        if (firstException) {
          throw firstException;
        }

        if (isAsync) {
          resolve(clock.now);
        } else {
          return clock.now;
        }
      }

      nextPromiseTick = isAsync && function () {
        try {
          compensationCheck();
          postTimerCall();
          doTickInner();
        } catch (e) {
          reject(e);
        }
      };

      compensationCheck = function () {
        // compensate for any setSystemTime() call during timer callback
        if (oldNow !== clock.now) {
          tickFrom += clock.now - oldNow;
          tickTo += clock.now - oldNow;
          previous += clock.now - oldNow;
        }
      };

      postTimerCall = function () {
        timer = firstTimerInRange(clock, previous, tickTo);
        previous = tickFrom;
      };

      return doTickInner();
    }
    /**
     * @param {tickValue} {String|Number} number of milliseconds or a human-readable value like "01:11:15"
     */


    clock.tick = function tick(tickValue) {
      return doTick(tickValue, false);
    };

    if (typeof _global.Promise !== "undefined") {
      clock.tickAsync = function tickAsync(ms) {
        return new _global.Promise(function (resolve, reject) {
          originalSetTimeout(function () {
            try {
              doTick(ms, true, resolve, reject);
            } catch (e) {
              reject(e);
            }
          });
        });
      };
    }

    clock.next = function next() {
      runJobs(clock);
      var timer = firstTimer(clock);

      if (!timer) {
        return clock.now;
      }

      clock.duringTick = true;

      try {
        clock.now = timer.callAt;
        callTimer(clock, timer);
        runJobs(clock);
        return clock.now;
      } finally {
        clock.duringTick = false;
      }
    };

    if (typeof _global.Promise !== "undefined") {
      clock.nextAsync = function nextAsync() {
        return new _global.Promise(function (resolve, reject) {
          originalSetTimeout(function () {
            try {
              var timer = firstTimer(clock);

              if (!timer) {
                resolve(clock.now);
                return;
              }

              var err;
              clock.duringTick = true;
              clock.now = timer.callAt;

              try {
                callTimer(clock, timer);
              } catch (e) {
                err = e;
              }

              clock.duringTick = false;
              originalSetTimeout(function () {
                if (err) {
                  reject(err);
                } else {
                  resolve(clock.now);
                }
              });
            } catch (e) {
              reject(e);
            }
          });
        });
      };
    }

    clock.runAll = function runAll() {
      var numTimers, i;
      runJobs(clock);

      for (i = 0; i < clock.loopLimit; i++) {
        if (!clock.timers) {
          return clock.now;
        }

        numTimers = keys(clock.timers).length;

        if (numTimers === 0) {
          return clock.now;
        }

        clock.next();
      }

      throw new Error("Aborting after running " + clock.loopLimit + " timers, assuming an infinite loop!");
    };

    clock.runToFrame = function runToFrame() {
      return clock.tick(getTimeToNextFrame());
    };

    if (typeof _global.Promise !== "undefined") {
      clock.runAllAsync = function runAllAsync() {
        return new _global.Promise(function (resolve, reject) {
          var i = 0;

          function doRun() {
            originalSetTimeout(function () {
              try {
                var numTimers;

                if (i < clock.loopLimit) {
                  if (!clock.timers) {
                    resolve(clock.now);
                    return;
                  }

                  numTimers = Object.keys(clock.timers).length;

                  if (numTimers === 0) {
                    resolve(clock.now);
                    return;
                  }

                  clock.next();
                  i++;
                  doRun();
                  return;
                }

                reject(new Error("Aborting after running " + clock.loopLimit + " timers, assuming an infinite loop!"));
              } catch (e) {
                reject(e);
              }
            });
          }

          doRun();
        });
      };
    }

    clock.runToLast = function runToLast() {
      var timer = lastTimer(clock);

      if (!timer) {
        runJobs(clock);
        return clock.now;
      }

      return clock.tick(timer.callAt - clock.now);
    };

    if (typeof _global.Promise !== "undefined") {
      clock.runToLastAsync = function runToLastAsync() {
        return new _global.Promise(function (resolve, reject) {
          originalSetTimeout(function () {
            try {
              var timer = lastTimer(clock);

              if (!timer) {
                resolve(clock.now);
              }

              resolve(clock.tickAsync(timer.callAt));
            } catch (e) {
              reject(e);
            }
          });
        });
      };
    }

    clock.reset = function reset() {
      nanos = 0;
      clock.timers = {};
      clock.jobs = [];
      clock.now = start;
    };

    clock.setSystemTime = function setSystemTime(systemTime) {
      // determine time difference
      var newNow = getEpoch(systemTime);
      var difference = newNow - clock.now;
      var id, timer;
      adjustedSystemTime[0] = adjustedSystemTime[0] + difference;
      adjustedSystemTime[1] = adjustedSystemTime[1] + nanos; // update 'system clock'

      clock.now = newNow;
      nanos = 0; // update timers and intervals to keep them stable

      for (id in clock.timers) {
        if (clock.timers.hasOwnProperty(id)) {
          timer = clock.timers[id];
          timer.createdAt += difference;
          timer.callAt += difference;
        }
      }
    };

    if (performancePresent) {
      clock.performance = Object.create(null);

      if (hasPerformancePrototype) {
        var proto = _global.Performance.prototype;
        Object.getOwnPropertyNames(proto).forEach(function (name) {
          if (name.indexOf("getEntries") === 0) {
            // match expected return type for getEntries functions
            clock.performance[name] = NOOP_ARRAY;
          } else {
            clock.performance[name] = NOOP;
          }
        });
      }

      clock.performance.now = function FakeTimersNow() {
        var hrt = hrtime();
        var millis = hrt[0] * 1000 + hrt[1] / 1000000;
        return millis;
      };
    }

    if (hrtimePresent) {
      clock.hrtime = hrtime;
    }

    return clock;
  }
  /**
   * @param config {Object} optional config
   * @param config.target {Object} the target to install timers in (default `window`)
   * @param config.now {number|Date}  a number (in milliseconds) or a Date object (default epoch)
   * @param config.toFake {string[]} names of the methods that should be faked.
   * @param config.loopLimit {number} the maximum number of timers that will be run when calling runAll()
   * @param config.shouldAdvanceTime {Boolean} tells FakeTimers to increment mocked time automatically (default false)
   * @param config.advanceTimeDelta {Number} increment mocked time every <<advanceTimeDelta>> ms (default: 20ms)
   */
  // eslint-disable-next-line complexity


  function install(config) {
    if (arguments.length > 1 || config instanceof Date || Array.isArray(config) || typeof config === "number") {
      throw new TypeError("FakeTimers.install called with " + String(config) + " install requires an object parameter");
    } // eslint-disable-next-line no-param-reassign


    config = typeof config !== "undefined" ? config : {};
    config.shouldAdvanceTime = config.shouldAdvanceTime || false;
    config.advanceTimeDelta = config.advanceTimeDelta || 20;
    var i, l;
    var target = config.target || _global;
    var clock = createClock(config.now, config.loopLimit);

    clock.uninstall = function () {
      return uninstall(clock, target, config);
    };

    clock.methods = config.toFake || [];

    if (clock.methods.length === 0) {
      // do not fake nextTick by default - GitHub#126
      clock.methods = keys(timers).filter(function (key) {
        return key !== "nextTick" && key !== "queueMicrotask";
      });
    }

    for (i = 0, l = clock.methods.length; i < l; i++) {
      if (clock.methods[i] === "hrtime") {
        if (target.process && typeof target.process.hrtime === "function") {
          hijackMethod(target.process, clock.methods[i], clock);
        }
      } else if (clock.methods[i] === "nextTick") {
        if (target.process && typeof target.process.nextTick === "function") {
          hijackMethod(target.process, clock.methods[i], clock);
        }
      } else {
        if (clock.methods[i] === "setInterval" && config.shouldAdvanceTime === true) {
          var intervalTick = doIntervalTick.bind(null, clock, config.advanceTimeDelta);
          var intervalId = target[clock.methods[i]](intervalTick, config.advanceTimeDelta);
          clock.attachedInterval = intervalId;
        }

        hijackMethod(target, clock.methods[i], clock);
      }
    }

    return clock;
  }

  return {
    timers: timers,
    createClock: createClock,
    install: install,
    withGlobal: withGlobal
  };
}

var defaultImplementation = withGlobal(globalObject);
exports.timers = defaultImplementation.timers;
exports.createClock = defaultImplementation.createClock;
exports.install = defaultImplementation.install;
exports.withGlobal = withGlobal;
export default exports;
export const timers = exports.timers,
      createClock = exports.createClock,
      install = exports.install;
const _withGlobal = exports.withGlobal;
export { _withGlobal as withGlobal };